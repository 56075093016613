import { useState, useRef, useEffect } from "react";

type FilterCallback<T> = (i: T) => boolean;

interface Filter<T> {
  name: keyof T;
}

type BaseFilter<T> = Filter<T> & {
  callback: FilterCallback<T>;
};

// @todo: is it possible to infer U from T?
type NestedFilter<T, U> = Filter<T> & {
  filters: BaseFilter<U>[];
};

const useFilter = <T extends object, U extends object>(data: Array<T>) => {
  const initialData = useRef(data);

  const [filtered, setFiltered] = useState(data);
  const [filters, setFilters] = useState<
    (BaseFilter<T> | NestedFilter<T, U>)[]
  >([]);

  // @note: currently this function works for a nesting of two levels deep,
  // however it could be reimplemented; recursively for n filter levels.
  const applyFilters = (
    data: T[],
    filters: (BaseFilter<T> | NestedFilter<T, U>)[]
  ) => {
    return filters.reduce((prevFiltered, filter) => {
      if ("callback" in filter) {
        return prevFiltered.filter(filter.callback);
      }

      return prevFiltered.reduce<T[]>((filteredByNested, filterItem) => {
        const filterArr = filterItem[filter.name];

        if (!Array.isArray(filterArr)) {
          throw new Error(
            `Property ${filter.name.toString()} is not of type array.`
          );
        }

        const filteredArray = filter.filters.reduce<U[]>(
          (nestedFiltered, nestedFilter) => {
            return nestedFiltered.filter(nestedFilter.callback);
          },
          filterArr
        );

        if (filteredArray.length === 0) {
          return filteredByNested;
        }

        return [
          ...filteredByNested,
          { ...filterItem, [filter.name]: filteredArray }
        ];
      }, []);
    }, data);
  };

  const filterBy = (filter: BaseFilter<T> | NestedFilter<T, U>) => {
    // @todo: set multiple nested filters.
    setFilters([...filters.filter(({ name }) => name !== filter.name), filter]);
  };

  const clearBy = (filter: Pick<Filter<T>, "name">) => {
    // @todo: clear multiple nested filters.
    setFilters([...filters.filter(({ name }) => name !== filter.name)]);
  };

  const clear = () => {
    setFiltered(initialData.current);
  };

  useEffect(() => {
    setFiltered(applyFilters(initialData.current, filters));
  }, [filters]);

  return {
    clear,
    clearBy,
    filterBy,
    filtered
  };
};

export default useFilter;
