import Button from "@edenred/button";
import Container from "@edenred/container";
import { MicroCopyContext } from "@edenred/micro-copy";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography
} from "@mui/material";
import { ChangeEvent, ReactNode, useContext, useMemo } from "react";
import { APICardTypes } from "../components/CardType";
import Filter from "../components/Filter";
import Retailers from "../components/Retailers";
import useFilter from "../hooks/useFilter";
import { Category } from "../templates/rewards";
import { Data as Retailer } from "../utils/filterAndMergeRetailers";
import useFilterReducer from "../utils/reducers/filterReducer";

type Props = {
  categories: Category[];
  featured?: ReactNode;
};

type CategoryProps = {
  title: Category["title"];
  retailers: Category["retailers"];
};

const FilterBar = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        mb: 8.75,
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          display: "none"
        },
        scrollbarWidth: "none"
      }}
    >
      {children}
    </Stack>
  );
};

const CategorySection = ({ title, retailers }: CategoryProps) => {
  return (
    <Box component="section">
      <Typography component="h2" variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Retailers data={retailers} />
    </Box>
  );
};

const getDistinctGiftCardTypes = (categories: Category[]): number[] => {
  const allCardTypes = Object.values(APICardTypes).filter(
    (value) => typeof value === "number"
  ) as number[];

  if (
    !categories ||
    categories.length === 0 ||
    !categories.some((c) => c.retailers && c.retailers.length !== 0)
  )
    return allCardTypes;

  const allRetailers = [...new Set(categories.flatMap((c) => c.retailers))];

  if (!allRetailers.some((r) => r.cardTypes && r.cardTypes.length !== 0))
    return allCardTypes;

  const allActiveCardTypes = [
    ...new Set(allRetailers.flatMap((r) => r.cardTypes.map((r) => r.id)))
  ];
  return allActiveCardTypes;
};

export default function Categories({ categories, featured }: Props) {
  const {
    clearBy,
    filtered: filteredCategories,
    filterBy,
    clear
  } = useFilter<Category, Retailer>(categories);

  const activeCardTyes = useMemo(() => {
    return getDistinctGiftCardTypes(categories);
  }, [categories]);

  const [{ category, retailers }, dispatch] = useFilterReducer();
  const getMicroCopy = useContext(MicroCopyContext);

  return (
    <Container>
      {(categories.length > 1 || activeCardTyes.length > 1) && (
        <FilterBar>
          {categories.length > 1 && (
            <Filter
              label={getMicroCopy("filters.category.filter-label")}
              isActive={category.isActive}
              isOpen={category.isOpen}
              handleToggle={(isOpen: boolean) => {
                dispatch({ filter: "category", payload: { isOpen } });
              }}
              optionsTitle={getMicroCopy("filters.category.options-title")}
              confirmButton={
                <Button
                  size="large"
                  fullWidth
                  onClick={() => {
                    dispatch({
                      filter: "category",
                      payload: { isOpen: false }
                    });
                  }}
                >
                  {getMicroCopy("filters.done")}
                </Button>
              }
              clearButton={
                <Button
                  variant="outlined"
                  onClick={() => {
                    clearBy({ name: "id" });
                    dispatch({
                      filter: "category",
                      payload: {
                        checkedFilters: [],
                        isActive: false,
                        isOpen: false
                      }
                    });
                  }}
                >
                  {getMicroCopy("filters.clear")}
                </Button>
              }
            >
              <FormGroup>
                {categories.map(({ title, id }) => {
                  return (
                    <FormControlLabel
                      key={`${title}-${id}`}
                      control={
                        <Checkbox
                          value={id}
                          checked={category.checkedFilters.includes(id)}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const { checked } = event.target;

                            const checkedFilters = checked
                              ? [...category.checkedFilters, id]
                              : [
                                  ...category.checkedFilters.filter(
                                    (checked) => checked !== id
                                  )
                                ];

                            dispatch({
                              filter: "category",
                              payload: {
                                isActive: checkedFilters.length > 0,
                                checkedFilters
                              }
                            });

                            if (checkedFilters.length === 0) {
                              clearBy({ name: "id" });
                              return;
                            }

                            const callback = (category: Category) => {
                              return checkedFilters.includes(category.id);
                            };

                            filterBy({
                              name: "id",
                              callback
                            });
                          }}
                        />
                      }
                      // @todo: check Maybe<string> for `title`
                      label={title || id}
                    />
                  );
                })}
              </FormGroup>
            </Filter>
          )}
          {activeCardTyes.length > 1 && (
            <Filter
              label={getMicroCopy("filters.card-type.filter-label")}
              isActive={retailers.isActive}
              isOpen={retailers.isOpen}
              handleToggle={(isOpen) =>
                dispatch({ filter: "retailers", payload: { isOpen } })
              }
              optionsTitle={getMicroCopy("filters.card-type.options-title")}
              confirmButton={
                <Button
                  size="large"
                  fullWidth
                  onClick={() => {
                    dispatch({
                      filter: "retailers",
                      payload: { isOpen: false }
                    });
                  }}
                >
                  {getMicroCopy("filters.done")}
                </Button>
              }
              clearButton={
                <Button
                  variant="outlined"
                  onClick={() => {
                    clearBy({ name: "retailers" });
                    dispatch({
                      filter: "retailers",
                      payload: {
                        checkedFilters: [],
                        isActive: false,
                        isOpen: false
                      }
                    });
                  }}
                >
                  {getMicroCopy("filters.clear")}
                </Button>
              }
            >
              <FormGroup>
                {activeCardTyes.map((cardTypeId) => (
                  <FormControlLabel
                    key={`card-type-${cardTypeId}`}
                    control={
                      <Checkbox
                        value={cardTypeId}
                        checked={retailers.checkedFilters.includes(cardTypeId)}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const { checked } = event.target;

                          const checkedFilters = checked
                            ? [...retailers.checkedFilters, cardTypeId]
                            : [
                                ...retailers.checkedFilters.filter(
                                  (checked) => checked !== cardTypeId
                                )
                              ];

                          dispatch({
                            filter: "retailers",
                            payload: {
                              checkedFilters,
                              isActive: checkedFilters.length > 0
                            }
                          });

                          if (checkedFilters.length === 0) {
                            clearBy({ name: "retailers" });
                            return;
                          }

                          filterBy({
                            name: "retailers",
                            filters: [
                              {
                                name: "cardTypes",
                                callback: (retailer) =>
                                  retailer.cardTypes.some((cardType) =>
                                    checkedFilters.includes(cardType.id)
                                  )
                              }
                            ]
                          });
                        }}
                      />
                    }
                    label={getMicroCopy(`filters.card-type.${cardTypeId}`)}
                  />
                ))}
              </FormGroup>
            </Filter>
          )}

          {/* @note: This Box ensures that the Button within does not shrink */}
          <Box>
            <Button
              disabled={!category.isActive && !retailers.isActive}
              variant="text"
              onClick={() => {
                clear();
                dispatch({ filter: "clearAll" });
              }}
            >
              <Typography
                textTransform="none"
                variant="button"
                component="span"
                noWrap
              >
                {getMicroCopy("filters.clear-all")}
              </Typography>
            </Button>
          </Box>
        </FilterBar>
      )}

      {featured}
      {filteredCategories.length > 0 &&
        filteredCategories.map(({ title, retailers }, i) => (
          <CategorySection
            key={`${i}-${title}`}
            title={title}
            retailers={retailers}
          />
        ))}
    </Container>
  );
}
