import { Box, Typography } from "@mui/material";
import Retailers from "./Retailers";
import { Data as Retailer } from "../utils/filterAndMergeRetailers";

type Props = {
  // @todo: resolve Maybe<string> for `title`
  title?: string;
  retailers: Retailer[];
};

const FeaturedRetailers = ({ title, retailers }: Props) => {
  return (
    <Box component="section">
      <Typography component="h2" variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Retailers data={retailers} gridSizes={{ md: 4 }} />
    </Box>
  );
};

export default FeaturedRetailers;
