import { useReducer } from "react";

// @todo: handle nested retailer filter states.
const initialState = {
  category: {
    isActive: false,
    checkedFilters: [] as string[],
    isOpen: false
  },
  retailers: {
    isActive: false,
    checkedFilters: [] as number[],
    isOpen: false
  }
};

type State = typeof initialState;

type FilterKeys = "category" | "retailers";

type Payload = {
  isActive?: boolean;
  isOpen?: boolean;
};

type CategoryPayload = Payload & {
  checkedFilters?: string[];
};

type RetailersPayload = Payload & {
  checkedFilters?: number[];
};

type Action = {
  filter: FilterKeys | "clearAll";
  payload?: CategoryPayload | RetailersPayload;
};

const reducer = (state: State, action: Action) => {
  switch (action.filter) {
    case "category":
      return {
        ...state,
        category: { ...state.category, ...(action.payload as CategoryPayload) }
      };
    case "retailers":
      return {
        ...state,
        retailers: {
          ...state.retailers,
          ...(action.payload as RetailersPayload)
        }
      };
    case "clearAll":
      return initialState;
    default:
      return initialState;
  }
};

const useFilterReducer = () =>
  useReducer((s: State, a: Action) => reducer(s, a), initialState);

export default useFilterReducer;
