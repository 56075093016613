import {
  Box,
  Card,
  CardContent,
  Typography,
  SxProps,
  Theme
} from "@mui/material";
import { ReactNode } from "react";

type RetailerCardProps = {
  title: string;
  image?: ReactNode;
  logo?: ReactNode;
  sxStyle?: SxProps;
  chip?: ReactNode;
};

const sxCard: SxProps<Theme> = {
  position: "relative"
};

const sxCardContent: SxProps = {
  display: "flex",
  flexFlow: "row",
  alignItems: "center"
};

const sxHeading: SxProps = {
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  lineHeight: 1.5,
  mb: 0
};

const sxImage: SxProps = {
  img: { objectFit: "cover", height: 116, width: 1 }
};

const sxChip: SxProps = {
  position: "absolute",
  top: 16,
  left: 16
};

const RetailerCard = ({
  title,
  image,
  logo,
  sxStyle,
  chip
}: RetailerCardProps) => {
  return (
    <Card elevation={8} sx={{ ...sxCard, ...sxStyle }}>
      {chip && <Box sx={sxChip}>{chip}</Box>}
      <Box sx={sxImage}>{image}</Box>
      <CardContent sx={sxCardContent}>
        {logo}
        <Typography gutterBottom variant="h6" component="span" sx={sxHeading}>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default RetailerCard;
