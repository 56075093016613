import { isMobileMediaQuery } from "@edenred/theme";
import { Box, Grid, GridSize } from "@mui/material";
import { Link } from "gatsby";
import RetailerCard from "../components/RetailerCard";
import type { Data as Retailer } from "../utils/filterAndMergeRetailers";

enum GridProps {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg"
}

export type Data = {
  data: Retailer[];
  gridSizes?: Partial<Record<GridProps, GridSize>>;
};

const ScrollContainer = ({ retailers }: { retailers: Retailer[] }) => {
  return (
    <Box
      sx={{
        mx: -2.5,
        pl: 2.5,
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          display: "none"
        },
        scrollbarWidth: "none"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          pt: 1,
          pb: 3,
          "& > *": {
            pr: 2
          }
        }}
      >
        {retailers.map((retailer, index) => {
          if (!retailer.title || !retailer.slug) {
            return;
          }

          return (
            <Link
              key={`${index}-${retailer.id}`}
              to={retailer.slug}
              style={{ textDecoration: "none" }}
            >
              <RetailerCard data={retailer} sxStyle={{ width: 246 }} />
            </Link>
          );
        })}
      </Box>
    </Box>
  );
};

const GridContainer = ({
  retailers,
  gridSizes
}: {
  retailers: Retailer[];
  gridSizes: Data["gridSizes"];
}) => (
  <Grid container spacing={3} sx={{ mb: 4.5 }}>
    {retailers.map((retailer, index) => {
      if (!retailer.title || !retailer.slug) {
        return;
      }

      return (
        <Grid item sm={4} md={3} {...gridSizes} key={`${index}-${retailer.id}`}>
          <Link
            key={`${index}-${retailer.id}`}
            to={retailer.slug}
            style={{ textDecoration: "none" }}
          >
            <RetailerCard data={retailer} />
          </Link>
        </Grid>
      );
    })}
  </Grid>
);

export default function Retailers({ data, gridSizes }: Data) {
  return (
    <>
      {isMobileMediaQuery() ? (
        <ScrollContainer retailers={data} />
      ) : (
        <GridContainer retailers={data} gridSizes={gridSizes} />
      )}
    </>
  );
}
