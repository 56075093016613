import EdenredRetailerCard from "@edenred/retailer-card";
import { SxProps } from "@mui/material";
import { graphql } from "gatsby";
import RetailerAvatar from "../components/RetailerAvatar";
import type { Data as Retailer } from "../utils/filterAndMergeRetailers";
import Image from "./Image";
import CompetitionChip from "../components/CompetitionChip";

export type Data = {
  data: Retailer;
  sxStyle?: SxProps;
};

const avatarStyle = {
  width: 60,
  height: 60,
  img: {
    objectFit: "contain",
    objectPosition: "center"
  },
  mr: 2
} as SxProps;

export default function IntegratedRetailerCard({ data, sxStyle }: Data) {
  const { title, images, logo, slug, competition } = data;
  const cardImage = images?.[0];

  if (!title || !slug) {
    return null;
  }

  return (
    <EdenredRetailerCard
      title={title}
      logo={<RetailerAvatar sxStyle={avatarStyle} title={title} logo={logo} />}
      image={cardImage && <Image data={cardImage} />}
      sxStyle={sxStyle}
      chip={competition?.title && <CompetitionChip label={competition.title} />}
    />
  );
}

export const query = graphql`
  fragment Retailer on ContentfulRetailer {
    __typename
    id
    title
    slug
    referenceId
    description {
      ...RichText
    }
    logo {
      ...Image
    }
    website
    images {
      ...Image
    }
    terms {
      ...TitleWithContent
    }
    competition {
      ...Competition
    }
    redeemOnline
    redeemInStore
  }
`;
