export const numbersExtractor = (
  numberList: string,
  options: { allowedRange?: number } = {}
) => {
  const matchRegex = /^(\d+|\d+-\d+)(?:,{1}(\d+|\d+-\d+))*$/;
  const rangeRegex = /^\d+-\d+$/;

  if (!matchRegex.test(numberList)) return [];
  const result: number[] = [];
  const splittedList = numberList.split(",");
  for (let i = 0; i < splittedList.length; i++) {
    const elem = splittedList[i];
    if (!rangeRegex.test(elem)) {
      if (!result.includes(Number(elem))) result.push(Number(elem));
      continue;
    }

    const rangeList = elem.split("-");
    const minRange = Number(rangeList[0]);
    let maxRange = Number(rangeList[1]);

    // To prevent any user errors, allow only range of a certain value.
    if (
      options.allowedRange &&
      options.allowedRange > 0 &&
      maxRange - minRange > options.allowedRange
    )
      maxRange = minRange + options.allowedRange;
    for (let j = minRange; j <= maxRange; j++) {
      if (!result.includes(j)) result.push(j);
      continue;
    }
  }

  return result.sort((a, b) => a - b);
};
