import { Link } from "gatsby";
import { styled } from "@mui/system";
import { Box, Card, Typography, Stack, SxProps } from "@mui/material";
import { Data as Retailer } from "../utils/filterAndMergeRetailers";
import Image from "./Image";
import RetailerAvatar from "./RetailerAvatar";
import CompetitionChip from "./CompetitionChip";
import { useMicroCopyContext } from "@edenred/micro-copy";
import Button from "@edenred/button";

export type Props = {
  index: number;
  activeIndex: number;
  retailer?: Retailer;
  image: GatsbyTypes.ContentfulImage;
};

const CarouselBanner = ({ index, retailer, image, activeIndex }: Props) => {
  const getMicroCopy = useMicroCopyContext();
  return (
    <Box key={index} sx={{ width: 1, height: 1, position: "relative" }}>
      <StyledLink
        as={retailer ? Link : "div"}
        to={retailer?.slug}
        sx={!retailer ? { width: "100%", height: "100%" } : undefined}
        tabIndex={-1}
      >
        <Image data={image} />
        {retailer && (
          <Card sx={sxCarouselCard}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <RetailerAvatar title={retailer.title} logo={retailer.logo} />
              <Stack direction="column" sx={{ ml: 2, mr: "auto" }}>
                <Typography variant="h6">{retailer.title}</Typography>
                {retailer.competition?.title && (
                  <CompetitionChip label={retailer.competition.title} />
                )}
              </Stack>
              <Button
                variant="contained"
                tabIndex={index === activeIndex ? 0 : -1}
              >
                {getMicroCopy("general.redeem")}
              </Button>
            </Box>
            {retailer.competition?.title && (
              <Typography
                variant="caption"
                component="p"
                sx={{
                  ml: 1,
                  mt: 0.5,
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  display: "-webkit-box"
                }}
              >
                {retailer.competition.description}
              </Typography>
            )}
          </Card>
        )}
      </StyledLink>
    </Box>
  );
};

// @note: This is needed so that the cursor for the carousel is a grab cursor
const StyledLink = styled(Link)(({ theme }) =>
  theme.unstable_sx({ cursor: "inherit" })
);

const sxCarouselCard: SxProps = {
  width: {
    sm: 480
  },
  borderRadius: 1.5,
  boxShadow: 0,
  opacity: 0.97,
  mb: {
    xs: 3,
    sm: 2.5
  },
  mx: 2.5,
  py: 1,
  pl: 1,
  pr: 2,
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0
};

export default CarouselBanner;
