import Carousel from "@edenred/carousel";
import Container from "@edenred/container";
import { useMicroCopyContext } from "@edenred/micro-copy";
import { isMobileMediaQuery } from "@edenred/theme";
import { SxProps } from "@mui/material";
import { graphql, PageProps } from "gatsby";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Categories from "../components/Categories";
import { PageWithHeaderAndFooter } from "../components/Page";
import Protected from "../components/Protected";
import { Context } from "../components/Provider";
import filterAndMergeRetailers, {
  Data as Retailer
} from "../utils/filterAndMergeRetailers";
import { fetchApiRetailers, fetchCustomer } from "../utils/fetchData";
import { Status } from "../utils/reducers/dataLoadReducer";
import Spinner from "@edenred/spinner";
import CarouselBanner, {
  Props as CarouselBannerProps
} from "../components/CarouselBanner";
import FeaturedRetailers from "../components/FeaturedRetailers";

type Props = PageProps<
  Pick<
    GatsbyTypes.Query,
    "contentfulClient" | "contentfulSite" | "allContentfulRetailer"
  >,
  ERSTypes.PageContext
>;

export type Category = Omit<GatsbyTypes.ContentfulRetailerSet, "retailers"> & {
  retailers: Retailer[];
};

export type PromotionalOffer = Omit<
  GatsbyTypes.ContentfulPromotionalOffer,
  "retailer"
> & {
  retailer?: Retailer;
};

export type PromotionalOfferSet = Omit<
  GatsbyTypes.ContentfulPromotionalOfferSet,
  "offers"
> & {
  offers: PromotionalOffer[];
};

export default function RewardsPage({ data, pageContext }: Props) {
  const {
    contentfulClient,
    contentfulSite,
    allContentfulRetailer: { nodes: contentfulRetailers }
  } = data;

  if (!contentfulClient || !contentfulSite || !contentfulRetailers) {
    throw Error("Expected data is missing");
  }

  const { homePath } = pageContext;
  const { enqueueSnackbar } = useSnackbar();
  const getMicroCopy = useMicroCopyContext();
  const {
    updateRetailerLookup,
    updateCustomer,
    updateApiRetailer,
    apiRetailer
  } = useContext(Context);
  const [categories, setCategories] = useState<Category[]>([]);
  const [featuredRetailers, setFeaturedRetailers] = useState<Retailer[]>([]);
  const [carouselItems, setCarouselItems] = useState<CarouselBannerProps[]>([]);
  const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
  const [apiRetailersStatus, setApiRetailersStatus] = useState(Status.Idle);

  const loadPageData = useCallback(async () => {
    setApiRetailersStatus(Status.Loading);
    const { data: customer } = await fetchCustomer(
      homePath.slice(1, homePath.length)
    );

    updateCustomer(customer ?? null);
    const response = await fetchApiRetailers(
      contentfulClient.referenceId!,
      customer?.ssvExternalRef,
      customer?.originatorId
    );

    updateApiRetailer(response.data ?? null);
    if (response.isSuccess && response.data && response.data.products)
      setApiRetailersStatus(Status.Done);
    else setApiRetailersStatus(Status.Failed);
  }, [contentfulClient, homePath]);

  useEffect(() => {
    if (apiRetailer) {
      setApiRetailersStatus(Status.Done);
      return;
    }
    loadPageData();
  }, [apiRetailer, loadPageData]);

  useEffect(() => {
    if (!apiRetailer) {
      return;
    }

    const retailerLookup = filterAndMergeRetailers(
      contentfulRetailers,
      apiRetailer.products,
      Number(contentfulClient.referenceId)
    );
    updateRetailerLookup(retailerLookup);
    if (Array.isArray(contentfulClient.carouselItems?.offers)) {
      setCarouselItems(
        (
          contentfulClient.carouselItems?.offers.filter(
            (offer) =>
              (offer.retailer && retailerLookup[offer.retailer.id]) ||
              !offer.retailer
          ) as PromotionalOffer[]
        ).map((offer) => ({
          index: 0,
          image: offer.image,
          retailer: offer.retailer
            ? retailerLookup[offer.retailer.id]
            : undefined
        })) as CarouselBannerProps[]
      );
    }
    if (Array.isArray(contentfulClient.featuredRetailers?.retailers)) {
      setFeaturedRetailers(
        contentfulClient
          .featuredRetailers!.retailers.map(({ id }) => retailerLookup[id])
          .filter((retailer) => !!retailer)
      );
    }
    if (Array.isArray(contentfulSite.categories)) {
      setCategories(
        contentfulSite.categories
          .filter(
            (c: GatsbyTypes.ContentfulRetailerSet) =>
              c.referenceId &&
              apiRetailer.categories &&
              apiRetailer.categories
                .map((r) => r.id)
                .some((r) => c.referenceId?.includes(r.toString()))
          )
          .map((category: GatsbyTypes.ContentfulRetailerSet) => ({
            ...category,
            retailers: category!
              .retailers!.map((retailer) => retailerLookup[retailer!.id])
              .filter((retailer) => !!retailer)
          }))
      );
    }
  }, [apiRetailer]);

  useEffect(() => {
    if (apiRetailersStatus === Status.Failed) {
      enqueueSnackbar(getMicroCopy("general.fetch-error"), {
        variant: "error"
      });
    }
  }, [apiRetailersStatus]);

  const sxTabletCarousel: SxProps = {
    height: 400,
    //For some reason, indiviual border radii aren't multiplied by theme spacing
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24
  };

  const carouselPromotions = useMemo(
    () =>
      carouselItems.length > 0
        ? carouselItems.map(
            (carouselItem, index) =>
              carouselItem.image && (
                <CarouselBanner
                  key={carouselItem.image.id}
                  index={index}
                  activeIndex={carouselActiveIndex}
                  image={carouselItem.image}
                  retailer={carouselItem.retailer}
                />
              )
          )
        : [],
    [carouselItems]
  );
  const showCarouselPromotions = carouselPromotions.length > 0;

  const isMobile = isMobileMediaQuery();

  return (
    <Protected homePath={homePath}>
      <PageWithHeaderAndFooter
        title={getMicroCopy("rewards.title")}
        description={getMicroCopy("rewards.description")}
        client={contentfulClient}
        homePath={homePath}
      >
        {apiRetailersStatus === Status.Loading && (
          <Spinner fullPage aria-label={getMicroCopy("general.loading")}>
            {getMicroCopy("general.loading")}
          </Spinner>
        )}
        {showCarouselPromotions && isMobile && (
          <Carousel
            alwaysShowDots
            aspectRatio="1.5"
            activeIndexListener={setCarouselActiveIndex}
            thumbnailLabel={(index) =>
              getMicroCopy("carousel.thumbnail-label", { index })
            }
          >
            {carouselPromotions}
          </Carousel>
        )}
        <Container>
          {showCarouselPromotions && !isMobile && (
            <Carousel
              alwaysShowDots
              sxStyle={sxTabletCarousel}
              activeIndexListener={setCarouselActiveIndex}
              thumbnailLabel={(index) =>
                getMicroCopy("carousel.thumbnail-label", { index })
              }
            >
              {carouselPromotions}
            </Carousel>
          )}
        </Container>
        {categories.filter((c) => c.retailers && c.retailers.length > 0)
          .length > 0 && (
          <Categories
            categories={categories.filter(
              (c) => c.retailers && c.retailers.length > 0
            )}
            featured={
              contentfulClient?.featuredRetailers &&
              featuredRetailers.length > 0 && (
                <FeaturedRetailers
                  title={contentfulClient?.featuredRetailers?.title}
                  retailers={featuredRetailers}
                />
              )
            }
          />
        )}
      </PageWithHeaderAndFooter>
    </Protected>
  );
}

export const pageQuery = graphql`
  query RewardsPageQuery($id: String!) {
    contentfulClient(id: { eq: $id }) {
      ...Client
    }
    contentfulSite {
      categories {
        id
        title
        referenceId
        retailers {
          ...Retailer
        }
      }
    }
    allContentfulRetailer {
      nodes {
        ...Retailer
      }
    }
  }
`;
