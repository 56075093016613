import FocusTrap from "focus-trap-react";
import {
  Box,
  Popper as MuiPopper,
  PopperProps,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { StyledProps } from "@edenred/types";
import { ReactNode, useRef } from "react";

type Props = StyledProps<
  {
    children: ReactNode;
    backdropRef?: React.RefObject<HTMLDivElement>;
  } & PopperProps
>;

type HeaderProps = StyledProps<{
  title: string;
  action?: ReactNode;
}>;

type ContentProps = StyledProps<{ children: ReactNode }>;

type FooterProps = StyledProps<{ children: ReactNode }>;

type ContainerProps = StyledProps<{ children: ReactNode }>;

const popperContainerStyle = {
  borderRadius: 2,
  mt: 1.5,
  maxHeight: 600,
  minWidth: 250,
  maxWidth: 350,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none"
} as const;

const popperHeaderStyle = {
  px: 2.5,
  pt: 2.25
} as const;

const popperContentStyle = {
  px: 3.5,
  py: 2.5
} as const;

const popperFooterStyle = {
  px: 2,
  pb: 3
} as const;

const Container = ({ children, sxStyle }: ContainerProps) => (
  <Paper sx={{ ...popperContainerStyle, ...sxStyle }}>{children}</Paper>
);

const Header = ({ title, action, sxStyle }: HeaderProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ ...popperHeaderStyle, ...sxStyle }}
  >
    <Typography variant="h6" component="span" noWrap sx={{ mr: 2 }}>
      {title}
    </Typography>
    {action}
  </Stack>
);

const Content = ({ children, sxStyle }: ContentProps) => (
  <Box sx={{ ...popperContentStyle, ...sxStyle }}>{children}</Box>
);

const Footer = ({ children, sxStyle }: FooterProps) => (
  <Box sx={{ ...popperFooterStyle, ...sxStyle }}>{children}</Box>
);

const Popper = ({
  sxStyle,
  id,
  anchorEl,
  placement = "bottom-start",
  open,
  children,
  backdropRef,
  ...rest
}: Props) => {
  const boxRef = useRef<HTMLDivElement>(null);

  return (
    <MuiPopper
      id={id}
      anchorEl={anchorEl}
      placement={placement}
      open={open}
      modifiers={[{ name: "flip", enabled: false }]}
      {...rest}
    >
      <FocusTrap
        active={open}
        containerElements={[boxRef.current!]}
        focusTrapOptions={{
          escapeDeactivates: false,
          clickOutsideDeactivates: true,
          fallbackFocus: () => backdropRef?.current!
        }}
      >
        {/* @note: This needs to have tabIndex=-1 or else MUI complains */}
        <Box ref={boxRef}>{children}</Box>
      </FocusTrap>
    </MuiPopper>
  );
};

Popper.Container = Container;
Popper.Header = Header;
Popper.Content = Content;
Popper.Footer = Footer;

export default Popper;
