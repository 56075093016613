import cookie from "cookie";
import ERSClientApi from "./api";

export const fetchCustomer = async (
  name: string
): Promise<FetchResponse<ErsApiTypes.CustomerResponse>> => {
  if (!name) return { isSuccess: false };

  const { error, data } = await new ERSClientApi().customer(name);
  return { isSuccess: !error && !!data, data: data, error: error?.message };
};

export const fetchApiRetailers = async (
  schemeId: string,
  customerExternalRef?: string,
  originatorId?: number
): Promise<FetchResponse<ErsApiTypes.ProductsResponse>> => {
  const cookies = cookie.parse(document.cookie || "");
  const { error, data } = await new ERSClientApi().retailers(
    cookies.token,
    schemeId,
    customerExternalRef,
    originatorId
  );
  return { isSuccess: !error && !!data, data: data, error: error?.message };
};

interface FetchResponse<T> {
  isSuccess: boolean;
  data?: T;
  error?: string;
}
