import { Chip, ChipProps } from "@mui/material";
import { lighten } from "@mui/system";
import CompetitionIcon from "@mui/icons-material/EmojiEventsOutlined";
import { StyledProps } from "@edenred/types";
import { getTheme } from "@edenred/theme";

type Props = StyledProps<ChipProps>;

const CompetitionChip = ({
  label,
  icon = <CompetitionIcon />,
  sxStyle,
  ...rest
}: Props) => {
  const theme = getTheme();

  return (
    <Chip
      icon={icon}
      label={label}
      size="small"
      sx={{
        bgcolor: lighten(theme.palette.info.light, 0.9),
        borderRadius: 0.5,
        color: "primary.main",
        fontWeight: 600,
        height: "auto",
        lineHeight: "22px",
        "& .MuiChip-icon": {
          color: "primary.main"
        },
        ...sxStyle
      }}
      {...rest}
    />
  );
};

export default CompetitionChip;
