import { useReducer } from "react";

interface State<T> {
  status: Status;
  data?: T;
  error?: string;
}

export enum Status {
  Idle = 0,
  Loading = 1,
  Done = 2,
  Failed = 3
}

type Action<T> =
  | {
      type: "loading";
    }
  | { type: "success"; payload: T }
  | { type: "fail"; payload?: string };

const initialState = <T>() =>
  ({
    status: Status.Idle
  } as State<T>);

const reducer = <T>(_: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case "loading":
      return { status: Status.Loading };
    case "success":
      return { status: Status.Done, data: action.payload };
    case "fail":
      return { status: Status.Failed, error: action.payload };
    default:
      return { status: Status.Idle };
  }
};

const useDataLoadReducer = <T>() =>
  useReducer(
    (s: State<T>, a: Action<T>) => reducer<T>(s, a),
    initialState<T>()
  );

export default useDataLoadReducer;
