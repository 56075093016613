import type { ProductData as ApiRetailer } from "../api/retailers";
import { numbersExtractor } from "./helpers";

export type Data = GatsbyTypes.ContentfulRetailer & Omit<ApiRetailer, "id">;

const filterCardTypes = (cardTypes: ERSTypes.CardType[]) => {
  if (!cardTypes) return [];

  /* Remove duplicate card types */
  return [
    ...new Map(cardTypes.map((cardType) => [cardType.id, cardType])).values()
  ];
};

export default function filterAndMergeRetailers(
  contentfulRetailers: readonly GatsbyTypes.ContentfulRetailer[],
  apiRetailers: ApiRetailer[],
  clientId: number
) {
  return contentfulRetailers.reduce<Record<string, Data>>((carry, current) => {
    const apiRetailer = apiRetailers.find(
      ({ id }) => id === current.referenceId
    );
    if (!apiRetailer) {
      return carry;
    }

    let removeCompetition = false;

    if (current.competition?.clientIdList) {
      const clientIdList = numbersExtractor(current.competition.clientIdList);
      removeCompetition =
        clientIdList.length > 0 && !clientIdList.includes(clientId);
    }

    const { id, cardTypes, ...rest } = apiRetailer;

    return {
      ...carry,
      [current.id]: {
        ...current,
        ...rest,
        competition: removeCompetition ? undefined : current.competition,
        cardTypes: filterCardTypes(cardTypes)
      }
    };
  }, {});
}
