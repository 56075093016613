import { useState, ReactNode, MouseEvent, useRef } from "react";
import {
  Backdrop,
  Box,
  ClickAwayListener,
  Stack,
  SwipeableDrawer,
  SxProps,
  Typography
} from "@mui/material";
import Button from "@edenred/button";
import { isMobileMediaQuery } from "@edenred/theme";
import { ExpandMore } from "@mui/icons-material";
import Popper from "@edenred/popper";

type FilterProps = {
  label: string;
  optionsTitle: string;
  isActive: boolean;
  isOpen: boolean;
  children: ReactNode;
  confirmButton: ReactNode;
  clearButton: ReactNode;
  handleToggle: (isOpen: boolean) => void;
};

const sxDrawer: SxProps = {
  "& .MuiDrawer-paper": {
    maxHeight: 0.7,
    //For some reason, indiviual border radii aren't multiplied by theme spacing
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16
  }
};

const sxScrollBoundary: SxProps = {
  content: "''",
  position: "absolute",
  left: 0,
  right: 0,
  pb: 4,
  pointerEvents: "none"
};

const sxHeader: SxProps = {
  position: "sticky",
  top: 0,
  height: 38,
  px: { xs: 3, sm: 2.5 },
  py: 0,
  mt: { xs: 3, sm: 2.25 },
  bgcolor: "common.white",
  zIndex: "speedDial",
  "&:after": {
    ...sxScrollBoundary,
    backgroundImage: "linear-gradient(white, transparent)",
    top: "100%"
  }
};

const sxDrawerList: SxProps = {
  px: 4.5,
  py: 2.5,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none"
};

const sxFooter: SxProps = {
  position: "sticky",
  bottom: 0,
  px: { xs: 3, sm: 2 },
  pb: { xs: 7, sm: 3 },
  width: 1,
  bgcolor: "common.white",
  zIndex: "speedDial",
  "&:before": {
    ...sxScrollBoundary,
    backgroundImage: "linear-gradient(transparent, white)",
    bottom: "100%"
  }
};

const Filter = ({
  label,
  optionsTitle,
  isActive = false,
  isOpen = false,
  children,
  clearButton,
  confirmButton,
  handleToggle
}: FilterProps) => {
  const [anchor, setAnchor] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const PRef = useRef<HTMLInputElement>(null);
  const backdropRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
    handleToggle(!isOpen);
    if (PRef.current !== null) {
      window.scrollTo({
        top: PRef.current?.offsetTop - 130,
        behavior: "smooth"
      });
    }
  };

  return (
    <Box>
      <Button
        id={label}
        variant={isActive ? "contained" : "outlined"}
        endIcon={<ExpandMore />}
        onClick={handleClick}
        size="medium"
      >
        <Typography
          textTransform="none"
          variant="button"
          component="span"
          noWrap
        >
          {label}
        </Typography>
      </Button>
      {isMobileMediaQuery() ? (
        <SwipeableDrawer
          aria-labelledby={label}
          anchor="bottom"
          open={isOpen}
          onClose={() => handleToggle(false)}
          onOpen={() => handleToggle(true)}
          sx={sxDrawer}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={sxHeader}
          >
            <Typography variant="h6" component="span" noWrap sx={{ mr: 2 }}>
              {optionsTitle}
            </Typography>
            {isActive && clearButton}
          </Stack>
          <Box sx={sxDrawerList}>{children}</Box>
          <Box sx={sxFooter}>{confirmButton}</Box>
        </SwipeableDrawer>
      ) : (
        <div ref={PRef}>
          <Popper
            backdropRef={backdropRef}
            anchorEl={anchor}
            open={isOpen}
            aria-labelledby={label}
            style={{ zIndex: 2 }}
          >
            <Popper.Container>
              <Popper.Header
                title={optionsTitle}
                action={isActive && clearButton}
                sxStyle={sxHeader}
              />
              <Popper.Content>{children}</Popper.Content>
              <Popper.Footer sxStyle={sxFooter}>{confirmButton}</Popper.Footer>
            </Popper.Container>
          </Popper>
          <Backdrop
            onClick={() => handleToggle(false)}
            ref={backdropRef}
            open={isOpen}
            style={{ zIndex: 1 }}
          />
        </div>
      )}
    </Box>
  );
};

export default Filter;
